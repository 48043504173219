<template>
  <b-button
    class="d-flex align-items-center"
    variant="purple-blue"
    v-b-tooltip.hover
    title="Open CR and full details"
    @click="openCRPageModal()"
  >
    <img
      src="../../../../../assets/img/logos/manheim-logo.png"
      alt="Manheim"
      class="cursor-pointer mr-1"
      width="20"
    />
    CR
  </b-button>
</template>

<script>
export default {
  name: "CRButton",
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openCRPageModal() {
      window.open(this.vehicle?.vehicleHasConditionalReports[0]?.url, "_blank");
    },
  },
};
</script>

<style>
</style>