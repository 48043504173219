export const POST_VEHICLE = "POST_VEHICLE";
export const POST_VEHICLE_SUCCESS = "POST_VEHICLE_SUCCESS";
export const POST_VEHICLE_ERROR = "POST_VEHICLE_ERROR";

export const SET_VEHICLE_VIN = "SET_VEHICLE_VIN";

export const GET_VEHICLE_BY_VIN = "GET_VEHICLE_BY_VIN";
export const GET_VEHICLE_BY_VIN_SUCCESS = "GET_VEHICLE_BY_VIN_SUCCESS";
export const GET_VEHICLE_BY_VIN_ERROR = "GET_VEHICLE_BY_VIN_ERROR";

export const POST_UPDATE_VEHICLE = "POST_UPDATE_VEHICLE";
export const POST_UPDATE_VEHICLE_SUCCESS = "POST_UPDATE_VEHICLE_SUCCESS";
export const POST_UPDATE_VEHICLE_ERROR = "POST_UPDATE_VEHICLE_ERROR";

export const POST_UPLOAD_FILE = "POST_UPLOAD_FILE";
export const POST_UPLOAD_FILE_SUCCESS = "POST_UPLOAD_FILE_SUCCESS";
export const POST_UPLOAD_FILE_ERROR = "POST_UPLOAD_FILE_ERROR";

export const POST_UPDATE_VEHICLES_STATUS_FROM_FILE =
  "POST_UPDATE_VEHICLES_STATUS_FROM_FILE";
export const POST_UPDATE_VEHICLES_STATUS_FROM_FILE_SUCCESS =
  "POST_UPDATE_VEHICLES_STATUS_FROM_FILE_SUCCESS";
export const POST_UPDATE_VEHICLES_STATUS_FROM_FILE_ERROR =
  "POST_UPDATE_VEHICLES_STATUS_FROM_FILE_ERROR";

export const GET_USERS_NICKNAMES = "GET_USERS_NICKNAMES";
export const GET_USERS_NICKNAMES_SUCCESS = "GET_USERS_NICKNAMES_SUCCESS";
export const GET_USERS_NICKNAMES_ERROR = "GET_USERS_NICKNAMES_ERROR";

export const CLEAR_VEHICLE_TO_EDIT = "CLEAR_VEHICLE_TO_EDIT";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_ERROR = "GET_LOCATIONS_ERROR";

export const PUT_VEHICLE_PRICE = "PUT_VEHICLE_PRICE";
export const PUT_VEHICLE_PRICE_SUCCESS = "PUT_VEHICLE_PRICE_SUCCESS";
export const PUT_VEHICLE_PRICE_ERROR = "PUT_VEHICLE_PRICE_ERROR";
